<template>
  <!-- 标题组件 -->
  <div class="content">
    <div class="titleBar">
      <div class="titleBarName">
        <h4 class="second-title" v-maia-ellipsis>{{ title }}</h4>
        <slot name="juniortitle"></slot>
        <div
          v-if="hasStatus.show"
          class="statusBox"
          :class="handelStatusBg(hasStatus.status)"
        >
          <span class="statusIcon" :class="handelConStatus(hasStatus.status)">
          </span>
          <span :class="handelNameColor(hasStatus.status)">{{
            hasStatus.status | conStatusNameFilter
          }}</span>
        </div>
      </div>
      <!-- 是否含有按钮组件 -->
      <div v-show="innerBtnBox">
        <slot name="btnBox" />
      </div>
    </div>
    <slot name="subTitle"></slot>
  </div>
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    title: String,
    innerBtnBox: {
      type: Boolean,
      default: false,
    },
    hasStatus: {
      type: Object,
      default: function () {
        return { show: false };
      },
    },
  },
  computed: {
    handelStatusBg() {
      return function (statusCode) {
        let iconColor;
        switch (statusCode) {
          case "approval":
          case "negotiating":
            iconColor = "bg-light-blue";
            break;
          case "approved":
          case "archived":
          case "stamped":
            iconColor = "bg-light-green";
            break;
          case "rejected":
            iconColor = "bg-light-pink";
            break;
          case "withdrawn":
          case "draft":
            iconColor = "bg-light-gray";
            break;
        }
        return iconColor;
      };
    },
    handelConStatus() {
      return function (statusCode) {
        let iconColor;
        switch (statusCode) {
          case "approval":
          case "negotiating":
            iconColor = "bg-dark-blue";
            break;
          case "approved":
          case "stamped":
          case "archived":
            iconColor = "bg-green";
            break;
          case "rejected":
            iconColor = "bg-dark-pink";
            break;
          case "withdrawn":
          case "draft":
            iconColor = "bg-gray";
            break;
        }
        return iconColor;
      };
    },

    handelNameColor() {
      return function (statusCode) {
        let iconColor;
        switch (statusCode) {
          case "approval":
          case "negotiating":
            iconColor = "text-blue";
            break;
          case "approved":
          case "stamped":
          case "archived":
            iconColor = "text-dark-green";
            break;
          case "rejected":
            iconColor = "text-pink";
            break;
          case "withdrawn":
          case "draft":
            iconColor = "text-dark-gray";
            break;
        }
        return iconColor;
      };
    },
  },
  data() {
    return {
      url: "/",
    };
  },
  methods: {
    getUrl() {
      this.url = this.$route.path;
    },
  },
  created() {
    this.getUrl();
  },
};
</script>

<style lang="less" scoped>
.titleBar {
  width: 100%;
  height: 48px;
  // border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  line-height: 34px;
  // padding: 6px 12px;
}
.titleBarName {
  max-width: 80%;
  // padding: 0px 6px;
  display: flex;
  justify-content: space-between;

  .statusBox {
    box-sizing: border-box;
    width: 60px;
    height: 20px;
    line-height: 14px;
    margin: 8px 0 0 10px;
    padding: 4px 6px;
    font-size: 12px;

    .statusIcon {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      margin-right: 2px;
      margin-bottom: 1px;
    }
  }
}
</style>