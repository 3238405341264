<template>
  <div class="content page-box">
    <TitleBar
      title="数据迁移"
      class="titleBox"
    >
    </TitleBar>
    <ul class="nav-list">
      <li v-for="(item, index) in navList" :key="index" @click="handleClick(item.name)" :class="$route.name === item.name ? 'active' : ''">
        {{ item.title }}
      </li>
    </ul>
    <div>
      <router-view></router-view>
    </div>
  </div>

</template>

<script>
import TitleBar from "@/components/common/TitleBar.vue";
const navList = [
  {
    title: '基础数据',
    name: 'baseData'
  },{
    title: '主合同数据',
    name: 'mainContract'
  },{
    title: '工联单数据',
    name: 'unionOrder'
  },{
    title: '补充协议数据',
    name: 'agreeMentMove'
  },{
    title: '合同变更数据',
    name: 'modifyMove'
  },{
    title: '合同附件数据',
    name: 'attachFile'
  },{
    title: '合同付款数据',
    name: 'contractPay'
  },{
    title: '审批记录数据',
    name: 'approveRecord'
  }
]
export default {
  name: "dataMove",
  components: {
    TitleBar
  },
  data() {
    return{
      navList: navList 
    }
  },
  methods: {
    handleClick(val) {
      this.$router.push({name: val})
    }
  },
  created() {    
    this.activeName = this.$route.name;
  }
};
</script>
<style lang="less" scoped>
.nav-list{
  display: flex;
  margin-top: 18px;
  padding-left: 18px;
  box-sizing: border-box;
  li{
    width: 96px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #F0D8A8;
    box-sizing: border-box;
    color: #997236;
    font-size: 12px;
    font-weight: 400;
    background: #fff;
    cursor: pointer;
    &:nth-child(n+2) {
      margin-left: -1px;
    }
    &.active{
      background: #F0D8A8;
      color: #323232;
    }
  }
}
</style>